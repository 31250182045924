<template>
  <span class="configure-activity-flyer-modal" v-if="canChange">
    <b-modal
      dialog-class="modal-lg"
      :id="modalId"
      @ok.prevent="onSave"
      ok-variant="primary"
      cancel-title="Annuler"
      ok-title="Enregistrer"
      :ok-disabled="!isValid()"
      @shown="onShow()"
    >
      <template v-slot:modal-title>
        <b>
          <i class="fas fa-newspaper"></i>&nbsp;
          Configuration du flyer
        </b>
      </template>
      <div v-if="error" class="errors-message">
        {{ error }}
      </div>
      <b-row>
        <b-col cols="8">
          <b-form-group
            label="Texte personnalisé"
            label-for="flyerBody"
          >
            <b-textarea id="flyerBody" v-model="flyerBody" rows="15"></b-textarea>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            label-for="flyerConfig"
            label="Gabarit de flyer"
          >
            <b-select id="flyerConfig" v-model="selectedFlyerConfigurationId">
              <b-select-option
                v-for="flyer of allFlyerConfigurations"
                :key="flyer.id"
                :value="flyer.id"
              >
                {{ flyer.name }}
              </b-select-option>
            </b-select>
          </b-form-group>
        </b-col>
      </b-row>
    </b-modal>
  </span>
</template>

<script>
import { mapActions } from 'vuex'
import { BackendMixin } from '@/mixins/backend'
import { BackendApi } from '@/utils/http'
import { makeActivityFlyer } from '@/types/activities'

export default {
  name: 'configure-activity-flyer-modal',
  components: { },
  mixins: [BackendMixin],
  props: {
    modalId: String,
    activity: Object,
    activityFlyerBody: String,
    flyerConfig: Number,
  },
  data() {
    return {
      flyerBody: '',
      allFlyerConfigurations: [],
      selectedFlyerConfigurationId: 0,
      error: '',
    }
  },
  computed: {
    canChange() {
      return this.hasPerm('activities.change_activityflyerconfiguration')
    },
  },
  watch: {
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    async init() {
      await this.loadFlyerConfiguration()
      this.setDefaultFlyer(false)
    },
    onShow() {
      this.selectedFlyerConfigurationId = this.flyerConfig
      this.flyerBody = this.activityFlyerBody
      this.setDefaultFlyer()
    },
    setDefaultFlyer() {
      if (this.selectedFlyerConfigurationId === 0) {
        const defaultFlyers = this.allFlyerConfigurations.filter(elt => !elt.isDefault)
        if (defaultFlyers.length) {
          this.selectedFlyerConfigurationId = defaultFlyers[0].id
        } else {
          this.selectedFlyerConfigurationId = 0
        }
      }
    },
    async onSave() {
      this.error = ''
      let url = '/api/activities/flyer-configuration/' + this.selectedFlyerConfigurationId + '/attach/'
      const data = {
        activity: this.activity.id,
        body: this.flyerBody,
      }
      let backendApi = new BackendApi('post', url)
      try {
        await backendApi.callApi(data)
        await this.addSuccess('Le flyer a été modifié')
        const flyerIndex = this.allFlyerConfigurations.map(elt => elt.id).indexOf(this.selectedFlyerConfigurationId)
        let flyer = makeActivityFlyer()
        if (flyerIndex >= 0) {
          flyer = this.allFlyerConfigurations[flyerIndex]
        }
        this.$emit(
          'updated', {
            body: this.flyerBody,
            flyer: flyer,
          }
        )
        this.$bvModal.hide(this.modalId)
      } catch (err) {
        this.error = this.getErrorText(err)
      }
    },
    async loadFlyerConfiguration() {
      try {
        let url = '/api/activities/flyer-configuration/'
        let backendApi = new BackendApi('get', url)
        let resp = await backendApi.callApi()
        this.allFlyerConfigurations = resp.data.map(makeActivityFlyer)
      } catch (err) {
        this.error = this.getErrorText(err)
      }
    },
    isValid() {
      return this.selectedFlyerConfigurationId > 0
    },
  },
  mounted() {
    this.init()
  },
}
</script>

<style scoped>
</style>
