<template>
  <div class="activity" v-if="canView">
    <page-header
      :title="title"
      :icon="activityIcon"
      :links="getLinks()"
    >
    </page-header>
    <loading-gif :loading-name="loadingName"></loading-gif>
    <b-row>
      <b-col cols="11"></b-col>
      <b-col cols="1">
        <div class="small-text">Nb de pages</div>
        <b-input class="small-input" type="number" step="1" min="1" v-model="pagesCount"></b-input>
      </b-col>
    </b-row>
    <div ref="printMe" v-if="activity" v-show="!isLoading(loadingName)">
      <div :style="getFlyerStyle()" v-for="elt in pages" :key="elt">
        <div :style="getMainStyle()">
          <div :style="getInnerMainStyle()">
            <div v-if="!flyer.noLogo && logoUrl">
              <img :src="logoUrl" :style="getLogoStyle()" />
            </div>
            <div :style="getTitleStyle()">{{ activity.name }}</div>
            <div :style="getBodyStyle()">
              <div v-if="activity.day && flyer.showDays" :style="getLineStyle()">
                {{ activity.day }} - {{ activity.getTimeRange() }}
              </div>
              <div v-if="activity.place && flyer.showPlace" :style="getLineStyle()">
                {{ activity.place.name }}
              </div>
              <div v-if="activity.about && flyer.showAbout" :style="getLineStyle()">
                {{ activity.about }}
              </div>
              <div v-if="activity.conditions && flyer.showConditions" :style="getLineStyle()">
                {{ activity.conditions }}
              </div>
              <div v-if="flyerBody" v-html="flyerBodyHtml">
              </div>
              <div v-if="footerHtml" :style="getFooterStyle()" v-html="footerHtml">
              </div>
            </div>
          </div>
        </div>
        <div :style="getActivityCalendarStyle()" v-if="showDates">
          <div
            v-for="obj of dates"
            :key="obj.id"
            class="calendar-date"
            :style="getCalendarStyle(obj)"
          >
            <span v-if="obj.holiday">
              <span v-if="obj.label">{{ obj.label }}<br />Vacances</span>
              <span v-else>
                {{ dateToString(obj.date, 'DD/MM/YYYY') }} - Vacances
              </span>
            </span>
            <span v-else>
              {{ dateToString(obj.date, 'dddd LL') }}
              <div v-if="obj.customPlace">{{ obj.customPlace }}</div>
              <div v-if="obj.customTime">{{ obj.customTime }}</div>
            </span>
          </div>
        </div>
      </div>
    </div>
    <configure-activity-flyer-modal
      modal-id="bv-configure-activity-flyer-modal"
      :activity="activity"
      :activity-flyer-body="flyerBody"
      :flyer-config="flyer.id"
      @updated="onFlyerUpdated"
    >
    </configure-activity-flyer-modal>
  </div>
</template>

<script>
// @ is an alias to /src
import router from '@/router'
import { mapMutations, mapActions } from 'vuex'
import LoadingGif from '@/components/Controls/LoadingGif'
import PageHeader from '@/components/Layout/PageHeader'
import ConfigureActivityFlyerModal from '@/components/Activities/ConfigureActivityFlyerModal.vue'
import { BackendMixin } from '@/mixins/backend'
import { makeActivity, makeActivityFlyer } from '@/types/activities'
import { makeActivityDate } from '@/types/agenda'
import { BackendApi, openDocument } from '@/utils/http'
import { slugify } from '@/utils/strings'
import store from '@/store'
import { dateToString } from '@/filters/texts'
import { compareDays } from '@/utils/sorting'
import { enrichedHtmlText } from '@/utils/html'

export default {
  name: 'activityFlyer',
  props: {
    activityId: String,
    youthHome: [String, Number],
  },
  mixins: [BackendMixin],
  components: {
    ConfigureActivityFlyerModal,
    LoadingGif,
    PageHeader,
  },
  data() {
    return {
      activity: null,
      loadingName: 'activityFlyer',
      activityDates: [],
      dates: [],
      flyer: makeActivityFlyer(),
      flyerBody: '',
      logoUrl: '',
      pagesCount: 1,
    }
  },
  created() {
    this.init()
  },
  watch: {
    activityId: function() {
      this.init()
    },
    activity: function(newValue, oldValue) {},
    title: function() {
      store.dispatch('changeNav', { title: this.title, icon: store.getters.navIcon, })
    },
  },
  computed: {
    title() {
      if (this.isYouthHome) {
        return this.youthHomeLabel
      } else {
        return this.activity ? (this.activity.getCategoryName() + ' ' + this.activity.name) : ''
      }
    },
    youthHomeLabel() {
      return store.getters.youthHomeLabel
    },
    youthHomeIcon() {
      return store.getters.youthHomeIcon
    },
    activityIcon() {
      if (this.isYouthHome) {
        return this.youthHomeIcon
      } else {
        return 'fas fa-chess-knight'
      }
    },
    isEvent() {
      return this.activity && this.activity.isEvent()
    },
    isYouthHome() {
      return +this.youthHome !== 0
    },
    activeDates() {
      return this.activityDates.filter(elt => !elt.absence)
    },
    canView() {
      return this.hasPerm('activities.view_coreactivity')
    },
    canChange() {
      return this.hasPerm('activities.change_activityflyerconfiguration')
    },
    flyerBodyHtml() {
      return enrichedHtmlText(this.flyerBody)
    },
    footerHtml() {
      if (this.flyer) {
        return enrichedHtmlText(this.flyer.footer)
      }
      return ''
    },
    showDates() {
      return (this.activityDates.length > 0) && !this.flyer.hideCalendar
    },
    pages() {
      return [...Array(+this.pagesCount).keys()]
    },
  },
  methods: {
    dateToString,
    ...mapActions(['addError', 'addSuccess']),
    ...mapMutations(['startLoading', 'endLoading']),
    async init() {
      await this.loadData()
      await this.loadActivityDates()
      await this.loadFlyer()
      this.buildDates()
    },
    async loadData() {
      this.startLoading(this.loadingName)
      try {
        let url = '/api/activities/activities/' + this.activityId + '/'
        if (+this.youthHome) {
          url = '/api/youth/activities/' + this.activityId + '/'
        }
        let backendApi = new BackendApi('get', url)
        let resp = await backendApi.callApi()
        this.activity = makeActivity(resp.data)
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
      this.endLoading(this.loadingName)
    },
    async loadFlyer() {
      if (this.activity) {
        try {
          let url = '/api/activities/flyer-configuration/flyer/'
          url += '?activity=' + this.activity.id
          let backendApi = new BackendApi('get', url)
          let resp = await backendApi.callApi()
          this.flyerBody = resp.data.body
          this.flyer = makeActivityFlyer(resp.data.config)
          this.logoUrl = resp.data['logo_url']
        } catch (err) {
          await this.addError(this.getErrorText(err))
        }
      }
    },
    onFlyerUpdated(event) {
      this.flyerBody = event.body
      this.flyer = event.flyer
    },
    async loadActivityDates() {
      this.startLoading(this.loadingName)
      try {
        let url = '/agenda/api/activity-dates/?activity=' + this.activityId
        let backendApi = new BackendApi('get', url)
        let resp = await backendApi.callApi()
        this.activityDates = resp.data.map(makeActivityDate)
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
      this.endLoading(this.loadingName)
    },
    viewActivity() {
      let dest
      if (this.isYouthHome) {
        dest = { name: 'youth-homes-detail', params: { activityId: '' + this.activity.id, }, }
      } else {
        dest = { name: 'activities-detail', params: { activityId: '' + this.activity.id, }, }
      }
      router.push(dest)
    },
    async printMe() {
      let docUrl = '/documents/standard/<key>/pdf/?hidePageNumber=1&colors=1'
      const docSlug = 'dates-' + slugify(this.activity.name)
      const docContent = this.$refs.printMe.innerHTML.toString()
      try {
        await openDocument(docUrl, docSlug, docContent)
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    configureFlyer() {
      this.$bvModal.show('bv-configure-activity-flyer-modal')
    },
    getLinks() {
      const links = []
      const ready = (!this.addManyDates && !this.editMode && !this.isLoading(this.loadingName))
      links.push(
        {
          id: 1,
          label: 'Voir l\'activité',
          callback: this.viewActivity,
          icon: this.activityIcon,
          cssClass: ready ? 'btn-secondary' : 'btn-secondary disabled',
        },
        {
          id: 2,
          label: 'PDF',
          callback: this.printMe,
          icon: 'fa fa-file-pdf',
          cssClass: ready ? 'btn-secondary' : 'btn-secondary disabled',
        },
        {
          id: 3,
          label: 'Config. flyer',
          callback: this.configureFlyer,
          icon: 'fa fa-newspaper',
          cssClass: ready ? 'btn-secondary' : 'btn-secondary disabled',
        }
      )
      return links
    },
    mergeHolidays(dates) {
      const validDates = []
      let firstHoliday = null
      // on parcourt toutes les dates pour ne garder qu'une date de vacances
      // dans le cas de dates consécutives
      for (const date of dates) {
        let ignore = false
        if (date.holiday) {
          if (firstHoliday) {
            ignore = true
            firstHoliday.merge.push(date)
          } else {
            firstHoliday = date
          }
        } else {
          firstHoliday = null
        }
        if (!ignore) {
          validDates.push(date)
        }
      }
      for (const obj of validDates) {
        if (obj.merge && obj.merge.length) {
          const lastObj = obj.merge[obj.merge.length - 1]
          const dt1 = dateToString(obj.date, 'DD/MM/YYYY')
          const dt2 = dateToString(lastObj.date, 'DD/MM/YYYY')
          obj.label = 'Du ' + dt1 + ' au ' + dt2
        }
      }
      return validDates
    },
    buildDates() {
      const allDates = this.activity.getAllDates()
      const extraDates = []
      const dates = allDates.map(
        elt => {
          return { date: elt, holiday: true, extra: false, merge: [], label: '', }
        }
      )
      let id = 0
      const regularTimeRange = this.activity.getTimeRange()
      const regularPlace = this.activity.place ? this.activity.place.id : 0
      for (const activityDate of this.activeDates) {
        const theDate = dateToString(activityDate.startDateTime, 'YYYY-MM-DD')
        const timeRange = activityDate.getTimeRange()
        const place = activityDate.place ? activityDate.place.id : 0
        const index = allDates.indexOf(theDate)

        const customPlace = ((regularPlace !== place) && activityDate.place) ? activityDate.place.name : ''
        const customTime = (regularTimeRange !== timeRange) ? timeRange : ''

        if (index >= 0) {
          dates[index].id = id++
          dates[index].holiday = false
          dates[index].customPlace = customPlace
          dates[index].customTime = customTime
        } else {
          extraDates.push(
            {
              id: id++,
              date: theDate,
              holiday: false,
              extra: true,
              customPlace: customPlace,
              customTime: customTime,
              merge: [],
              label: '',
            }
          )
        }
      }
      const rawDates = dates.concat(extraDates).sort(
        (elt1, elt2) => compareDays(elt1.date, elt2.date)
      )
      let lastNonHolidaysIndex = 0
      for (let index = 0; index < rawDates.length; index++) {
        const elt = rawDates[index]
        if (!elt.holiday) {
          lastNonHolidaysIndex = index
        }
      }
      if (lastNonHolidaysIndex >= 0) {
        this.dates = rawDates.slice(0, lastNonHolidaysIndex + 1)
      } else {
        this.dates = []
      }
      if (!this.flyer.dontMergeHolidays) {
        this.dates = this.mergeHolidays(this.dates)
      }
    },
    getCalendarStyle(obj) {
      let style = {
        fontSize: '11px',
        padding: '1px',
        marginBottom: '1px',
        border: 'solid 1px #888',
        textAlign: 'center',
      }
      if (this.flyer.smallCalendar) {
        style = {
          fontSize: '9px',
          textAlign: 'center',
          border: 'solid 1px #888',
        }
      }
      if (obj.holiday) {
        if (this.flyer.smallCalendar) {
          style.display = 'none'
        } else {
          style.background = '#888'
          style.color = '#ccc'
        }
      } else if (obj.extra || obj.customPlace || obj.customTime) {
        style.fontWeight = 'bold'
      }
      return style
    },
    getMainStyle() {
      return {
        display: 'inline-block',
        width: this.showDates ? '70%' : '95%',
        textAlign: 'center',
        height: '220mm',
        verticalAlign: 'top',
      }
    },
    getInnerMainStyle() {
      return {
        padding: '' + this.flyer.padding + 'px',
      }
    },
    getActivityCalendarStyle() {
      return {
        display: 'inline-block',
        width: '25%',
        verticalAlign: 'top',
      }
    },
    getTitleStyle() {
      return {
        textAlign: this.flyer.titleAlignment,
        fontWeight: 'bold',
        fontSize: '' + this.flyer.titleFont + 'px',
      }
    },
    getBodyStyle() {
      return {
        textAlign: this.flyer.bodyAlignment,
        fontSize: '' + this.flyer.bodyFont + 'px',
      }
    },
    getLogoStyle() {
      return {
        width: '' + this.flyer.logoSize + 'px',
      }
    },
    getFooterStyle() {
      return {
        display: 'block',
        position: 'absolute',
        bottom: '0',
        width: this.showDates ? '65%' : '95%',
        textAlign: this.flyer.footerAlignment,
        borderTop: 'solid 1px #222',
        paddingTop: '20px',
        fontSize: '' + this.flyer.footerFont + 'px',
      }
    },
    getFlyerStyle() {
      return {
        position: 'relative',
        height: '260mm',
        width: '210mm',
      }
    },
    getLineStyle() {
      return {
        marginBottom: '10px',
      }
    },
  },
}
</script>

<style scoped lang="less">
</style>
